import * as React from 'react'

const AccessRight = ({ user }) => {
  const renderFlag = (bool) =>
    bool ? (
      <i className="fas fa-check" style={{ color: 'green' }}></i>
    ) : (
      <i className="fas fa-times" style={{ color: 'red' }}></i>
    )

  return (
    <>
      <div className="mt-3">Event Access Rights:</div>
      <div className="card mt-2 p-3 w-100">
        <div className="mb-2">
          <span className="fw-bold">3-Day Pass:&nbsp;</span>
          {renderFlag(user.physical)}
        </div>
      </div>
      <div className='card mt-2 p-2 w-100'>
        <div>
          <span className="fw-bold">Badge Printed?&nbsp;</span>
          {renderFlag(user.badgePrinted)}
        </div>
        <div>
          <span className="fw-bold">Meal Pref.&nbsp;</span>
          {user.mealPreference}
        </div>
      </div>
      
      <a className="mt-2 text-center d-block" href={`/admin/users/${user.id}`} target="_blank">
        <span>View User Profile</span>
        <i className="fas fa-arrow-right ms-2"></i>
      </a>
    </>
  )
}

export default AccessRight
