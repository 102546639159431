import * as React from 'react'

interface OverviewProps {
  programmeImages: any
}

const Overview: React.FC<OverviewProps> = ({
  programmeImages,
}) => {
  console.log(`🚀 ~ programmeImages:`, programmeImages)
  const [tab, setTab] = React.useState('day1')

  const switchTab = (tab: string) => {
    setTab(tab)
  }
  return (
    <div className='container my-5'>
      {/* <ul id="pills-tab" className='px-0 nav-pills mb-4'>
        <li className='nav-item'>
          <button className={`${tab === 'day1' && 'active'} nav-link`} onClick={() => switchTab('day1')}>Day One</button>
        </li>
        <li className='nav-item'>
          <button className={`${tab === 'day2' && 'active'} nav-link`} onClick={() => switchTab('day2')}>Day Two</button>
        </li>
        <li className='nav-item'>
          <button className={`${tab === 'day3' && 'active'} nav-link`} onClick={() => switchTab('day3')}>Day Three</button>
        </li>
      </ul> */}
      {/* <div className='mb-4'>
        <h5 className='fw-bold text-pink'>Programme Overview</h5>
        <h2 className='text-orange mt-0'>DAY {tab === 'day1' ? 'ONE' : tab === 'day2' ? 'TWO' : 'THREE'}</h2>
      </div> */}
      <div className="d-flex justify-content-center">
      <img src={programmeImages[tab].url} className='img-fluid' style={{maxWidth: '650px'}} />
      </div>
    </div>
  )
}

export default Overview
