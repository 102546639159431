import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import HallSessions from "./HallSessions";

export const DAY_ZERO_HALLS: Array<ProgrammeSession["hall"]> = [
  "MAIN LOBBY",
  "BALLROOM 1 & 2",
  "MEETING ROOM 5 & 6",
];
export const DAY_ONE_HALLS: Array<ProgrammeSession["hall"]> = [
  "BALLROOM 1 & 2",
  "MEETING ROOM 5 & 6",
];
export const DAY_TWO_HALLS: Array<ProgrammeSession["hall"]> = [
  "BALLROOM 1 & 2",
  "MEETING ROOM 5 & 6",
];
export const DAY_THREE_HALLS: Array<ProgrammeSession["hall"]> = [
  "BALLROOM 1 & 2",
  "MEETING ROOM 5 & 6",
];

const EVENT_DATES = [
  { dayNum: 0, dayName: "Pre-Congress Workshops", date: "6th Nov 2024" },
  { dayNum: 1, dayName: "Day 1", date: "7th Nov 2024" },
  { dayNum: 2, dayName: "Day 2", date: "8th Nov 2024" },
  { dayNum: 3, dayName: "Day 3", date: "9th Nov 2024" },
];

export const FACULTY_TYPE = [
  "Chairperson",
  "Citation",
  "Co-Chair",
  "Emcee",
  "Judge",
  "Guest Of Honor",
  "Speaker",
  "Adjudicator",
  "Moderator",
] as const;

// export enum CATEGORY {
//   "asad_1",
//   "asad_2",
//   "msc",
//   "msn",
//   "ccndp",
//   "bnc",
//   "allied_health",
// }

export interface Faculty {
  id: number;
  fullName: string;
  avatar: string;
  shortProfile: string;
}

export interface FacultyWithType extends Faculty {
  facultyType: (typeof FACULTY_TYPE)[number];
}

export interface ProgrammeSession {
  id: number;
  title: string;
  desc?: string;
  startAt: string;
  endAt: string;
  date: string; // 11 JUN | SATURDAY
  time: string; // 10:00 to 11:00
  day: 0 | 1 | 2 | 3;
  hallOverwrite?: string;
  hall: "MAIN LOBBY" | "BALLROOM 1 & 2" | "MEETING ROOM 5 & 6";
  // category:
  //   | "asad_1"
  //   | "asad_2"
  //   | "msc"
  //   | "msn"
  //   | "ccndp"
  //   | "bnc"
  //   | "allied_health";
  faculties: Array<FacultyWithType>;
  subsessions: Array<ProgrammeSubsession>;
  secondsTillStart: number;
  durationInSeconds: number;
  ended?: boolean;
}

export interface ProgrammeSubsession {
  id: number;
  title?: string;
  description?: string;
  speakers: Array<Faculty>;
  time: string; // 10:00 to 11:00
}

const Programme = ({ defaultDay }) => {
  const [loading, setLoading] = useState(false);
  const [day, setDay] = useState(0);

  const [data, setData] = useState([]);

  const [activeLecture, setActiveLecture] =
    useState<ProgrammeSession["hall"]>("BALLROOM 1 & 2");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/programme_sessions?day=${day}`)
      .then((response) => {
        setData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [day]);

  const dayClass = "btn-custom my-1 fw-bold me-2";
  const catClass = "btn-custom-orange my-1 fw-bold me-2";

  const getTabColor = (hallname: string) => {
    switch (hallname) {
      case "MAIN LOBBY":
        return "#2e5d9c";
      case "BALLROOM 1 & 2":
        return "#59d4e8";
      case "MEETING ROOM 5 & 6":
        return "#f9a826";
      default:
        return "#7f7f7f";
    }
  };

  if (loading) {
    return (
      <div className="loader">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="container pb-5" id="programmeAgenda">
      <div className="filterBtns d-flex flex-column align-items-center">
        {/* 1st row */}
        <div className="my-3 text-center">
          {EVENT_DATES.map(({ dayNum, dayName, date }) => (
            <button
              key={dayNum}
              className={`${dayNum === day && "dark"} ${dayClass}`}
              onClick={() => {
                setDay(dayNum);
              }}
            >
              <div>{dayName}</div>
              <div>{date}</div>
            </button>
          ))}
        </div>

        {/* 2nd row */}
        {/* {(day === 1 || day === 2 || day === 3) && (
          <div className="my-3 text-center" style={{ maxWidth: "800px" }}>
            <button
              className={`${
                category === CATEGORY.asad_1 && "dark"
              } ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.asad_1);
                setActiveLecture("ASAD 1 Track");
              }}
            >
              ASAD Track
            </button>
            <button
              className={`${category === CATEGORY.msc && "dark"} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.msc);
                setActiveLecture("MSC Track");
              }}
            >
              MSC Track
            </button>
            <button
              className={`${category === CATEGORY.msn && "dark"} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.msn);
                setActiveLecture("MSN Track");
              }}
            >
              MSN Track
            </button>
            <button
              className={`${category === CATEGORY.ccndp && "dark"} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.ccndp);
                setActiveLecture("CCNDP Track");
              }}
            >
              CCNDP Track
            </button>
            <button
              className={`${category === CATEGORY.bnc && "dark"} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.bnc);
                setActiveLecture("BNC Track");
              }}
            >
              BNC Track
            </button>
            <button
              className={`${
                category === CATEGORY.allied_health && "dark"
              } ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.allied_health);
                setActiveLecture("Allied Health Track");
              }}
            >
              Allied Health Track
            </button>
          </div>
        )} */}
      </div>

      {/* 3rd row */}
      {day === EVENT_DATES[0].dayNum && (
        <div className="mt-5">
          <div className="d-block d-lg-none">
            {DAY_ZERO_HALLS.map((hall) => (
              <button
                key={hall}
                className={`hallTabs fw-bold ${
                  activeLecture !== hall && "deselected"
                }`}
                style={{
                  background: getTabColor(hall),
                  textTransform: "capitalize",
                }}
                onClick={() => setActiveLecture(hall)}
              >
                {hall}
              </button>
            ))}
          </div>

          <div className="row nogutters">
            {DAY_ZERO_HALLS.map((hall) => (
              // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
              <div className="col-lg-6" key={hall}>
                <HallSessions
                  hallName={hall}
                  activeHall={activeLecture}
                  programmes={data.filter(
                    (programme: ProgrammeSession) => programme.hall === hall
                  )}
                  getTabColor={getTabColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {day === EVENT_DATES[1].dayNum && (
        <div className="mt-5">
          <div className="d-block d-lg-none">
            {DAY_ONE_HALLS.map((hall) => (
              <button
                key={hall}
                className={`hallTabs fw-bold ${
                  activeLecture !== hall && "deselected"
                }`}
                style={{
                  background: getTabColor(hall),
                  textTransform: "capitalize",
                }}
                onClick={() => setActiveLecture(hall)}
              >
                {hall}
              </button>
            ))}
          </div>

          <div className="row nogutters">
            {DAY_ONE_HALLS.map((hall) => (
              // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
              <div className="col-lg-6" key={hall}>
                <HallSessions
                  hallName={hall}
                  activeHall={activeLecture}
                  programmes={data.filter(
                    (programme: ProgrammeSession) => programme.hall === hall
                  )}
                  getTabColor={getTabColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {day === EVENT_DATES[2].dayNum && (
        <div className="mt-5">
          <div className="d-block d-lg-none">
            {DAY_TWO_HALLS.map((hall) => (
              <button
                key={hall}
                className={`hallTabs fw-bold ${
                  activeLecture !== hall && "deselected"
                }`}
                style={{
                  background: getTabColor(hall),
                  textTransform: "capitalize",
                }}
                onClick={() => setActiveLecture(hall)}
              >
                {hall}
              </button>
            ))}
          </div>

          <div className="row nogutters">
            {DAY_TWO_HALLS.map((hall) => (
              // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
              <div className="col-md-6" key={hall}>
                <HallSessions
                  hallName={hall}
                  activeHall={activeLecture}
                  programmes={data.filter(
                    (programme: ProgrammeSession) => programme.hall === hall
                  )}
                  getTabColor={getTabColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {day === EVENT_DATES[3].dayNum && (
        <div className="mt-5">
          <div className="d-block d-lg-none">
            {DAY_THREE_HALLS.map((hall) => (
              <button
                key={hall}
                className={`hallTabs fw-bold ${
                  activeLecture !== hall && "deselected"
                }`}
                style={{
                  background: getTabColor(hall),
                  textTransform: "capitalize",
                }}
                onClick={() => setActiveLecture(hall)}
              >
                {hall}
              </button>
            ))}
          </div>

          <div className="row nogutters">
            {DAY_THREE_HALLS.map((hall) => (
              // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
              <div className="col-md-6" key={hall}>
                <HallSessions
                  hallName={hall}
                  activeHall={activeLecture}
                  programmes={data.filter(
                    (programme: ProgrammeSession) => programme.hall === hall
                  )}
                  getTabColor={getTabColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Programme;
