// src/controllers/hello_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'country',
    'ic',
    // 'pdpa',
    // 'profession',
    // 'professionOther',
    // 'subspecialty',
    // 'subspecialtyOther',
    // 'submit',
    // 'meal',
    // 'mealOther',
  ]
  connect() {
    this.hasIcTarget ? this.toggleIC() : null
    // this.hasProfessionTarget ? this.toggleProfessionOther() : null
    // this.hasSubspecialtyTarget ? this.toggleSubspecialtyOther() : null
    // this.hasMealTarget ? this.toggleMealOther() : null
  }

  toggleIC() {
    if (this.countryTarget.value == 'MY') {
      this.icTarget.disabled = false
    } else {
      this.icTarget.disabled = true
    }
  }

  // togglePDPA() {
  //   if (this.pdpaTarget.checked) {
  //     this.submitTarget.disabled = false
  //   } else {
  //     this.submitTarget.disabled = true
  //   }
  // }

  // toggleProfessionOther() {
  //   if (['Others'].includes(this.professionTarget.value)) {
  //     this.professionOtherTarget.classList.add('d-block')
  //     this.professionOtherTarget.classList.remove('d-none')
  //     if (this.professionOtherTarget.value.length < 1) {
  //       this.professionOtherTarget.focus()
  //     }
  //   } else {
  //     this.professionOtherTarget.classList.add('d-none')
  //     this.professionOtherTarget.classList.remove('d-block')
  //   }
  //   if (this.professionTarget.value == 'Orthopaedic Surgeon') {
  //     this.subspecialtyTarget.classList.add('d-block')
  //     this.subspecialtyTarget.classList.remove('d-none')
  //   } else {
  //     this.subspecialtyTarget.classList.add('d-none')
  //     this.subspecialtyTarget.classList.remove('d-block')
  //   }
  // }

  // toggleSubspecialtyOther() {
  //   if (this.subspecialtyTarget.value == 'Others') {
  //     this.subspecialtyOtherTarget.classList.add('d-block')
  //     this.subspecialtyOtherTarget.classList.remove('d-none')
  //     if (this.subspecialtyOtherTarget.value.length < 1) {
  //       console.log(this.subspecialtyOtherTarget.focus())
  //     }
  //   } else {
  //     this.subspecialtyOtherTarget.classList.add('d-none')
  //     this.subspecialtyOtherTarget.classList.remove('d-block')
  //   }
  // }

  // toggleMealOther() {
  //   if (this.mealTarget.value == 'Other') {
  //     this.mealOtherTarget.classList.add('d-block')
  //     this.mealOtherTarget.classList.remove('d-none')
  //     if (this.mealOtherTarget.value.length < 1) {
  //       console.log(this.mealOtherTarget.focus())
  //     }
  //   } else {
  //     this.mealOtherTarget.classList.add('d-none')
  //     this.mealOtherTarget.classList.remove('d-block')
  //   }
  // }
}
