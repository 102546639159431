import * as React from 'react'
import { ProgrammeSession } from './Programme'
import SessionCard from './Session/SessionCard'

interface HallSessionsProps {
  hallName: string
  programmes: Array<ProgrammeSession>
  activeHall: ProgrammeSession['hall']
  getTabColor: any
}

const HallSessions: React.FC<HallSessionsProps> = ({
  hallName,
  programmes,
  activeHall,
  getTabColor,
}) => {
  const active = activeHall === hallName
  const pastSessions = programmes?.filter(
    (s) => s.durationInSeconds + s.secondsTillStart < 0
  )
  const upcomingSessions = programmes?.filter(
    (s) => s.durationInSeconds + s.secondsTillStart >= 0
  )

  return (
    <div className={`hallContainer ${active && 'd-block'} d-lg-block`}>
      <div className="d-none d-lg-block">
        <div className="hallTabs text-center" style={{background: getTabColor(hallName) }}>
          {hallName}
        </div>
      </div>
      <div className={`sessionContainer p-0 p-md-3`}>
        {upcomingSessions?.map((session) => (
          <SessionCard key={session.id} {...session} />
        ))}
        {pastSessions?.map((session) => (
          <SessionCard ended key={session.id} {...session} />
        ))}
      </div>
    </div>
  )
}

export default HallSessions
