import axios from 'axios'
import React, { useState } from 'react'
import { ProductCard } from './ProductCard'

const ProductSelection = ({
  userId,
  token,
  url,
  purchasedAnyPhysical,
  purchasedAnyWorkshop,
  physical,
  workshop1,
  workshop2,
  sst,
}) => {
  const [selected, setSelected] = useState(null)
  const [selectedWorkshop1, setSelectedWorkshop1] = useState(null) // [null, product_id]
  const [selectedWorkshop2, setSelectedWorkshop2] = useState(null) // [null, product_id]

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[selected, selectedWorkshop1, selectedWorkshop2]}
        />

        <h3 className="text-center text-navy mt-4">Select Workshop Ticket</h3>

        <ProductCard
          cardTitle={'Pre-Congress Ultrasound-guided Workshop'}
          products={workshop1}
          selectedState={selectedWorkshop1}
          setSelectedState={setSelectedWorkshop1}
          activated={(selected || !purchasedAnyWorkshop)}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedWorkshop1(null);
              setSelectedWorkshop2(null);
            }
          }}
          handleRadio={(e) => {
            setSelectedWorkshop1(null);
            setSelectedWorkshop2(null);
          }}
        />
        <h6 className="text-center text-muted mt-4">OR</h6>

        <ProductCard
          cardTitle={'Pre-Congress Cadaveric Workshop'}
          products={workshop2}
          selectedState={selectedWorkshop2}
          setSelectedState={setSelectedWorkshop2}
          activated={(selected || !purchasedAnyWorkshop)}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedWorkshop1(null);
              setSelectedWorkshop2(null);
            }
          }}
          handleRadio={(e) => {
            setSelectedWorkshop1(null);
            setSelectedWorkshop2(null);
          }}
        />

        <h3 className="text-center text-navy mt-4">Select Main Congress Ticket</h3>

        <ProductCard
          cardTitle={'MAS ASM 2024 Main Congress Ticket'}
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated={!purchasedAnyPhysical}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null)
            }
          }}
          handleRadio={() => {
            setSelected(null)
          }}
        />
  
        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur 8% SST
          </strong>
        )}
        <button
          type="submit"
          className="btn btn-primary d-block text-white mx-auto mt-4"
          disabled={selected === null && selectedWorkshop1 === null && selectedWorkshop2 === null}
        >
          Purchase Pass
        </button>
      </form>
    </div>
  )
}

export default ProductSelection
